import styled, { css } from "styled-components";
import { Typography } from "../Typography/Typography";

export const Card = styled.div<{ scaleAnimation?: boolean }>`
    padding: var(--spacing-8);
    background-color: var(--white);
    border-radius: 1.6rem;
    border: 1px solid var(--gray-200);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: flex;
    flex-direction: column;

    ${(props) =>
        props.scaleAnimation &&
        css`
            transition: all 1s;

            &:hover {
                transform: scale(1.05);
            }
        `}

    @media screen and (max-width: 30em) {
        padding-right: var(--spacing-6);
        padding-left: var(--spacing-6);
    }
`;

const CardHeadingBase = styled(Typography)`
    margin-bottom: var(--spacing-4);
`;

export const CardHeading: React.FC<{ children: string }> = ({ children }) => {
    return (
        <CardHeadingBase variant="h5" align="center" weight="medium">
            {children}
        </CardHeadingBase>
    );
};

export const CardToast = styled.div<{ $isSuccess: boolean }>`
    position: absolute;
    height: 4rem;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: ${(props) =>
        props.$isSuccess ? "var(--green-50)" : "var(--red-50)"};
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 0 var(--spacing-8);
    display: flex;
    align-items: center;

    p {
        color: ${(props) =>
            props.$isSuccess ? "var(--green-600)" : "var(--red-500)"};
    }
    color: ${(props) =>
        props.$isSuccess ? "var(--green-600)" : "var(--red-500)"};
`;
