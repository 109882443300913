import { FC } from "react";
import { TopNavHashLink, TopNavLink, TopNav } from "../../components";

const NavLinks: FC<{ closeMenu?: () => void }> = () => {
    return (
        <>
            <li>
                <TopNavHashLink to="../">Main</TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#instructor">Your Mentor</TopNavHashLink>
            </li>
            <li>
                <TopNavLink to="../contact">Contact</TopNavLink>
            </li>
            <li>
                <TopNavLink to="../mini-bootcamp">Mini Bootcamp</TopNavLink>
            </li>
        </>
    );
};

export const MiniBootcampTopNav = () => {
    return (
        <TopNav mobileNavLinks={<NavLinks />}>
            <NavLinks />
        </TopNav>
    );
};
