import styled from "styled-components";
import { useState } from "react";
import {
    Container,
    SectionName,
    SectionHeading,
    SectionSubHeading,
    IconSquareBackground,
    SectionBase,
    CalendlyPopup,
} from "../../../components";
import { Typography, Button } from "../../../../design-system";
import { ADMISSION_STEPS } from "../../../constants";
const Section = styled(SectionBase)`
    background-color: var(--yellow-50);
`;

const AdmissionsSteps = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-16);
    margin-bottom: var(--spacing-16);

    @media screen and (max-width: 60em) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 40em) {
        grid-template-columns: 1fr;
    }
`;

const AdmissionsStepHeading = styled(Typography)`
    margin-bottom: var(--spacing-2);
`;

const AdmissionsCTAs = styled.div`
    display: flex;
    justify-content: center;
    gap: var(--spacing-3);

    @media screen and (max-width: 30em) {
        flex-direction: column;

        Button,
        a {
            width: 100%;
        }
    }
`;

const AdmissionsStepIconBackground = styled(IconSquareBackground)`
    margin-bottom: 2rem;
`;

const Admissions = () => {
    const [showCalendly, setShowCalendly] = useState(false);
    return (
        <Section id="admissions">
            <Container>
                <SectionName align="center">Admissions</SectionName>
                <SectionHeading align="center">
                    No prior experience required
                </SectionHeading>
                <SectionSubHeading align="center">
                    Our selection process is based on student's grit and
                    aspiration.
                </SectionSubHeading>
                <AdmissionsSteps>
                    {ADMISSION_STEPS.map((step, idx) => {
                        return (
                            <div key={idx}>
                                <AdmissionsStepIconBackground variant="contained">
                                    <img src={step.imgSrc} alt="" />
                                </AdmissionsStepIconBackground>
                                <AdmissionsStepHeading
                                    variant="textXL"
                                    weight="semibold"
                                    align="left"
                                >
                                    {step.name}
                                </AdmissionsStepHeading>
                                <Typography
                                    variant="textL"
                                    weight="normal"
                                    align="left"
                                >
                                    {step.description}
                                </Typography>
                            </div>
                        );
                    })}
                </AdmissionsSteps>
                <AdmissionsCTAs>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        onClick={() => setShowCalendly(true)}
                    >
                        Need Consultation?
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        renderAsLink
                        navigateTo="apply"
                    >
                        Apply Now
                    </Button>
                    <CalendlyPopup
                        url="https://calendly.com/turantech/15?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=7f56d9"
                        onClose={() => setShowCalendly(false)}
                        open={showCalendly}
                    />
                </AdmissionsCTAs>
            </Container>
        </Section>
    );
};

export { Admissions };
