import { Option, OptionValue } from "./Select";

export const getSelectLabel = (options: Option[], value: OptionValue) => {
    return options.filter((option) => option.value === value)[0].label;
};

export const getFilteredOptions = (
    dropdownData: Option[],
    searchValue: string
) => {
    return dropdownData.filter((option: Option) => {
        return option.value
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());
    });
};
