import styled from "styled-components";
import {
    SectionName,
    SectionHeading,
    SectionSubHeading,
    SectionBase,
    Container
} from "../../components";
import { MainTopNav } from "../main/sections";
import { Button } from "../../../design-system";

const CTAButton = styled(Button)`
    margin: 0 auto;
`;
export const Error = () => {
    return (
        <>
            <MainTopNav />
            <SectionBase>
                <Container>
                    <SectionName align="center">Oops... </SectionName>
                    <SectionHeading align="center">
                        You have the wrong address
                    </SectionHeading>
                    <SectionSubHeading align="center">
                        Sorry, the page you are looking for doesn't exist.
                    </SectionSubHeading>
                    <CTAButton
                        variant="contained"
                        size="large"
                        renderAsLink
                        navigateTo={""}
                    >
                        Take me home{" "}
                    </CTAButton>
                </Container>
            </SectionBase>
        </>
    );
};
