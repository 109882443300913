import styled, { css } from "styled-components";

const CommonStyles = css<{ error?: boolean }>`
    display: block;
    font-family: "Maven Pro", sans-serif;
    width: 100%;
    padding: 0 1.4rem;
    border: 1px solid var(--gray-300);
    border-radius: 0.8rem;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: var(--gray-900);
    font-size: 16px;
    line-height: var(--line-height-3);
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--gray-500);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--gray-500);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--gray-500);
    }
    &:focus:not(:disabled),
    &:active:not(:disabled) {
        border: 1px solid var(--primary-300);
        box-shadow: 0px 0px 0px 4px var(--primary-100);
    }

    &:hover:not(:disabled, :focus, :active) {
        border: 1px solid var(--primary-600);
    }
    &:disabled {
        background-color: var(--gray-50);
        border: 1px solid var(--gray-300);
    }

    ${(props) =>
        props.error &&
        css`
            border: 1px solid var(--red-300);

            &:focus:not(:disabled) {
                box-shadow: 0px 0px 0px 4px var(--red-100);
                border: 1px solid var(--red-300);
            }

            &:hover:not(:disabled) {
                border: 1px solid var(--red-300);
            }
            &:disabled {
                background-color: var(--gray-50);
            }
        `}
`;

export const InputBase = styled.input<{ error?: boolean }>`
    ${CommonStyles}
    height: 4.4rem;
`;

export const TextArea = styled.textarea<{ error?: boolean }>`
    ${CommonStyles}
    resize: none;
`;

export const InputLabel = styled.label<{ error?: boolean }>`
    display: block;
    color: ${(props) => (props.error ? "var(--red-700)" : "var(--gray-700)")};
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    font-weight: var(--font-weight-500);
`;

export const InputHint = styled.span<{ error?: boolean }>`
    display: block;
    color: ${(props) => (props.error ? "var(--red-500)" : "var(--gray-600)")};
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    font-weight: var(--font-weight-400);
`;
