import styled from "styled-components";
import { Typography } from "../../../../design-system";
import {
    Container,
    IconRoundBackground,
    SectionName,
    SectionHeading,
    SectionSubHeading,
    SectionBase,
} from "../../../components";
import terminalSquare from "../../../assets/icons/terminalSquare.svg";
import globe from "../../../assets/icons/globe.svg";
import briefcase from "../../../assets/icons/briefcase.svg";
import calendar from "../../../assets/icons/calendar.svg";
import people from "../../../assets/icons/people.svg";
import dollar from "../../../assets/icons/dollar.svg";

const Section = styled(SectionBase)`
    background-color: var(--green-25);
`;

const ProgramFacts = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-16);

    @media screen and (max-width: 60em) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 40em) {
        grid-template-columns: 1fr;
    }
`;

const ProgramFactIconBackground = styled(IconRoundBackground)`
    margin-bottom: 2rem;
`;

const ProgramFactHeading = styled(Typography)`
    margin-bottom: var(--spacing-2);
`;

const Highlighter = styled.span`
    font-weight: var(--font-weight-500);
`;

export const HowItWorks = () => {
    return (
        <Section id="howitworks">
            <Container>
                <SectionName align="left">How It Works</SectionName>
                <SectionHeading align="left">
                    We designed this program for your success!
                </SectionHeading>
                <SectionSubHeading align="left">
                    While you learn from Industry Experts, Consistency and
                    Dedication is the key to succeed in program.
                </SectionSubHeading>

                <ProgramFacts>
                    <div>
                        <ProgramFactIconBackground>
                            <img src={terminalSquare} alt="" />
                        </ProgramFactIconBackground>
                        <ProgramFactHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            Job-ready skills
                        </ProgramFactHeading>
                        <Typography
                            variant="textL"
                            weight="normal"
                            align="left"
                        >
                            Our curriculum provides in-depth{" "}
                            <Highlighter>Fullstack Javascript</Highlighter>{" "}
                            training aimed at immediate workforce entry. This
                            program covers both front-end and back-end
                            development and includes hands-on Computer Science
                            training.
                        </Typography>
                    </div>
                    <div>
                        <ProgramFactIconBackground>
                            <img src={globe} alt="Location" />
                        </ProgramFactIconBackground>
                        <ProgramFactHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            Live and Onsite
                        </ProgramFactHeading>
                        <Typography
                            variant="textL"
                            weight="normal"
                            align="left"
                        >
                            You have the flexibility to choose between two
                            format options: either spend the first{" "}
                            <Highlighter>8 weeks </Highlighter>
                            online and then transition to{" "}
                            <Highlighter>12 weeks</Highlighter> of onsite
                            training in Brooklyn, NY, or complete the entire
                            20-week program online to accommodate your needs.
                        </Typography>
                    </div>
                    <div>
                        <ProgramFactIconBackground>
                            <img src={calendar} alt="Calendar" />
                        </ProgramFactIconBackground>
                        <ProgramFactHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            Daytime and Evening + Weekend Schedules
                        </ProgramFactHeading>
                        <Typography
                            variant="textL"
                            weight="normal"
                            align="left"
                        >
                            We designed our program to be accessible and
                            convenient for you. Whether you need to keep your
                            day job or you want to spend the day coding, we have
                            a schedule specifically tailored to you.
                        </Typography>
                    </div>
                    <div>
                        <ProgramFactIconBackground>
                            <img src={people} alt="" />
                        </ProgramFactIconBackground>
                        <ProgramFactHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            15-Student Classes
                        </ProgramFactHeading>
                        <Typography
                            variant="textL"
                            weight="normal"
                            align="left"
                        >
                            There are no compromises when it comes to the
                            student experience. We keep class sizes small to
                            create the optimal learning environment. This way,
                            Lead Instructors are able to provide personalized
                            attention to every student.
                        </Typography>
                    </div>
                    <div>
                        <ProgramFactIconBackground>
                            <img src={briefcase} alt="" />
                        </ProgramFactIconBackground>
                        <ProgramFactHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            Career Support
                        </ProgramFactHeading>
                        <Typography
                            variant="textL"
                            weight="normal"
                            align="left"
                        >
                            Our goal is the same as your goals: to help you
                            launch a career as a Software Engineer. A Career
                            Advisor will work with each student on navigating
                            the industry, creating a resume, curating a LinkedIn
                            profile, preparing for interviews, and providing a
                            way to track job applications.
                        </Typography>
                    </div>
                    <div>
                        <ProgramFactIconBackground>
                            <img src={dollar} alt="" />
                        </ProgramFactIconBackground>
                        <ProgramFactHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            Payment Plans
                        </ProgramFactHeading>
                        <Typography
                            variant="textL"
                            weight="normal"
                            align="left"
                        >
                            You can pay upfront (and get a discount of $500) or
                            break up your tuition into four installments without
                            paying any interest. Additionally, we provide
                            merit-based scholarships and tuition assistance to
                            select students.
                        </Typography>
                    </div>
                </ProgramFacts>
            </Container>
        </Section>
    );
};
