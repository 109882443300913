import {
    Header,
    HowItWorks,
    Instructor,
    Curriculum,
    Programs,
    Admissions,
    MainTopNav
} from "./sections";

const Main = () => {
    return (
        <>
            <MainTopNav />
            <Header />
            <HowItWorks />
            <Instructor />
            <Curriculum />
            <Programs />
            <Admissions />
        </>
    );
};

export { Main };
