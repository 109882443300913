import styled from "styled-components";
import {
    SectionHeading,
    SectionName,
    Container,
    SectionBase,
} from "../../../components";
import { Typography } from "../../../../design-system";
import me from "../../../assets/images/me.jpg";
import ancestry from "../../../assets/logos/ancestry.svg";
import digilock from "../../../assets/logos/digilock.svg";
import entrata from "../../../assets/logos/entrata.svg";
import lendingclub from "../../../assets/logos/lendingclub.svg";
import proofpoint from "../../../assets/logos/proofpoint.svg";
import shift4 from "../../../assets/logos/shift4.svg";
import status from "../../../assets/logos/status.svg";
import tata from "../../../assets/logos/tata.svg";
import elementus from "../../../assets/logos/elementus.svg";

const Section = styled(SectionBase)`
    background-color: var(--white);
`;

const SectionContent = styled(Container)`
    display: flex;
    gap: var(--spacing-16);
    align-items: center;

    @media screen and (max-width: 60em) {
        flex-direction: column;
    }
`;

const InstructorImageWrapper = styled.div`
    flex-basis: calc((100% - 2 * var(--spacing-8)) / 3);
    flex-shrink: 0;
`;

const InstructorImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 6.4rem 0;
`;

const InstructorFactParagraph = styled(Typography)`
    margin-bottom: var(--spacing-4);
`;

const CompanyLogos = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-8);
    margin-top: var(--spacing-8);

    img {
        width: auto;
        height: 2rem;
    }

    img:nth-child(6) {
        height: 2.5rem;
    }

    img:nth-child(7) {
        height: 3.5rem;
    }
`;

const Instructors = () => {
    return (
        <Section id="instructor">
            <SectionContent>
                <InstructorImageWrapper>
                    <InstructorImage src={me} />
                </InstructorImageWrapper>
                <div>
                    <SectionName align="left">
                        Founder &amp; Instructor
                    </SectionName>
                    <SectionHeading align="left">
                        Let's introduce Ali.
                    </SectionHeading>
                    <InstructorFactParagraph
                        align="left"
                        weight="normal"
                        variant="textL"
                    >
                        He has been in Tech since 2011. Since then, he has
                        worked with multi-billion dollar companies and tiny
                        startups. Startups and Fortune 1000 companies, including
                        Top US Banks, Hospitals, Universities, and Venues like
                        T-Mobile Arena, are using the applications and solutions
                        he built.
                    </InstructorFactParagraph>
                    <InstructorFactParagraph
                        align="left"
                        weight="normal"
                        variant="textL"
                    >
                        However, the road could have been smoother. After moving
                        to the United States in late 2011, He had to do all
                        kinds of jobs to survive: From being a breakfast maker
                        at Comfort Inn in Missouri to Butchering in California,
                        from Bussboying in New York to dishwashing in Miami.
                    </InstructorFactParagraph>
                    <InstructorFactParagraph
                        align="left"
                        weight="normal"
                        variant="textL"
                    >
                        Now, with hard-won wisdom and the support of Almighty,
                        he turns his attention to aiding your journey..
                    </InstructorFactParagraph>
                    <CompanyLogos>
                        <img src={elementus} alt="Elementus" />
                        <img src={proofpoint} alt="Proofpoint" />
                        <img src={ancestry} alt="Ancestry" />
                        <img src={lendingclub} alt="lendingclub" />
                        <img src={shift4} alt="Shift4" />
                        <img src={entrata} alt="Entrata" />
                        <img src={status} alt="Status" />
                        <img src={tata} alt="Tata" />
                        <img src={digilock} alt="Digilock" />
                    </CompanyLogos>
                </div>
            </SectionContent>
        </Section>
    );
};

const Instructor = () => { return <></>;}

export {Instructor, Instructors };
