import { useState } from "react";
import styled from "styled-components";
import {
    Card,
    CardToast,
    Typography,
    Button,
    Input,
    Select,
    useName,
    usePhoneNumber,
    useEmailInput,
    useTextArea,
    useSelect
} from "../../../design-system";
import { Mailer } from "../../api";

const ageRanges = [
    { label: "Under 20", value: "Under 20" },
    { label: "20 - 30", value: "20 - 30" },
    { label: "31 - 40", value: "31 - 40" },
    { label: "41 - 50", value: "41 - 50" }
];

const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
].map((state) => {
    return { value: state, label: state };
});

const englishLevels = [
    {
        value: "Basic",
        label: "Basic"
    },
    { value: "Not bad", label: "No bad" },
    {
        value: "OK",
        label: "OK"
    },
    { value: "Fluent", label: "Fluent" }
];

const RegistrationForm = styled(Card)`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: start;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-6);
    align-items: start;
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-10);
    height: max-content;

    @media screen and (max-width: 90em) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 65em) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 50em) {
        grid-template-columns: 1fr;
    }
`;
const FormButton = styled(Button)`
    grid-column: 1/3;
    justify-self: end;

    @media screen and (max-width: 90em) {
        grid-column: 1/4;
    }

    @media screen and (max-width: 65em) {
        grid-column: 1/3;
    }

    @media screen and (max-width: 50em) {
        grid-column: 1/2;
    }
`;

const StatementInput = styled(Input)`
    grid-column: 1/3;

    @media screen and (max-width: 50em) {
        grid-column: 1/2;
    }
`;

const mailer = new Mailer();
export const MiniBootcampRegistration = () => {
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
    const [isFormSubmitError, setIsFormSubmitError] = useState(false);
    const [
        firstName,
        setFirstName,
        isNameError,
        nameErrorMessage,
        validateName,
        isNameValidated
    ] = useName("");

    const [
        lastName,
        setLastName,
        isLastNameError,
        lastNameErrorMessage,
        validateLastName,
        isLastNameValidated
    ] = useName("");

    const [
        email,
        setEmail,
        isEmailError,
        emailErrorMessage,
        validateEmail,
        isEmailValidated
    ] = useEmailInput("");

    const [
        phoneNumber,
        setPhoneNumber,
        isPhoneNumberError,
        phoneNumberErrorMessage,
        validatePhoneNumber,
        isPhoneNumberValidated
    ] = usePhoneNumber("");

    const [
        ageRange,
        setAgeRange,
        isAgeRangeError,
        ageRangeError,
        validateAgeRange,
        isAgeRangeValidated
    ] = useSelect("");

    const [
        state,
        setState,
        isStateError,
        stateError,
        validateState,
        isStateValidated
    ] = useSelect("");

    const [
        englishLevel,
        setEnglishLevel,
        isEnglishLevelError,
        englishLevelError,
        validateEnglishLevel,
        isEnglishLevelValidated
    ] = useSelect("");

    const [
        haveLaptop,
        setHaveLaptop,
        isHaveLoptopError,
        haveLaptopError,
        validateHaveLaptop,
        isHaveLaptopValidated
    ] = useSelect("");

    const isFormSubmittable =
        isNameValidated &&
        isLastNameValidated &&
        isEmailValidated &&
        isPhoneNumberValidated &&
        isStateValidated &&
        isAgeRangeValidated &&
        isEnglishLevelValidated &&
        isHaveLaptopValidated;

    const resetFormData = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setState("");
        setAgeRange("");
        setEnglishLevel("");
        setHaveLaptop("");
        setStatement("");
    };

    const [statement, setStatement] = useTextArea("");

    const handleRegistration = async () => {
        if (
            isFormSubmittable &&
            !isFormSubmitSuccess &&
            !isFormSubmitError &&
            !isFormSubmitting
        ) {
            try {
                setIsFormSubmitting(true);
                await mailer.registerForMiniBootcamp({
                    applicantFirstName: firstName,
                    applicantLastName: lastName,
                    applicantEmail: email,
                    applicantPhoneNumber: phoneNumber,
                    applicantState: state,
                    applicantAgeRange: ageRange,
                    applicantEnglishLevel: englishLevel,
                    applicantHasLaptop: haveLaptop,
                    applicantMessage: statement
                });
                resetFormData();
                setIsFormSubmitSuccess(true);
                setIsFormSubmitting(false);

                setTimeout(() => {
                    setIsFormSubmitSuccess(false);
                }, 10000);
            } catch (error) {
                resetFormData();
                setIsFormSubmitError(true);
                setIsFormSubmitting(false);

                setTimeout(() => {
                    setIsFormSubmitError(false);
                }, 10000);
            }
        } else {
            validateName();
            validateLastName();
            validateEmail();
            validatePhoneNumber();
            validateState(state, "State cannot be blank");
            validateAgeRange(ageRange, "Age range cannot be blank");
            validateEnglishLevel(englishLevel, "English level cannot be blank");
            validateHaveLaptop(haveLaptop, "Oh, you missed this field");
        }
    };

    return (
        <RegistrationForm>
            <Input
                label="First Name"
                placeholder="First Name"
                value={firstName}
                onChange={setFirstName}
                error={isNameError}
                hintMessage={nameErrorMessage}
                onBlur={validateName}
                id="firstName"
                disabled={isFormSubmitting}
            />
            <Input
                label="Last Name"
                placeholder="Last Name"
                value={lastName}
                onChange={setLastName}
                error={isLastNameError}
                hintMessage={lastNameErrorMessage}
                onBlur={validateLastName}
                id="lastName"
                disabled={isFormSubmitting}
            />
            <Input
                type="email"
                label="Your Email"
                placeholder="you@example.com"
                value={email}
                onChange={setEmail}
                error={isEmailError}
                hintMessage={emailErrorMessage}
                onBlur={validateEmail}
                id="emailAddress"
                disabled={isFormSubmitting}
            />
            <Input
                type="tel"
                label="Phone Number"
                placeholder="(123) 456-7890"
                value={phoneNumber}
                onChange={setPhoneNumber}
                error={isPhoneNumberError}
                hintMessage={phoneNumberErrorMessage}
                onBlur={validatePhoneNumber}
                id="phoneNumber"
                disabled={isFormSubmitting}
            />
            <Select
                label={"Location"}
                headerLabel="Select your state"
                value={state}
                onSelect={(state) => {
                    setState(state);
                    validateState(state, "");
                }}
                options={states}
                error={isStateError}
                hintMessage={stateError}
                validateOnBlur={() =>
                    validateState(state, "State cannot be blank")
                }
                disabled={isFormSubmitting}
                searchable
            />
            <Select
                label={"Age Range"}
                headerLabel="Select your age range"
                value={ageRange}
                onSelect={(ageRange) => {
                    setAgeRange(ageRange);
                    validateAgeRange(ageRange, "");
                }}
                options={ageRanges}
                error={isAgeRangeError}
                hintMessage={ageRangeError}
                validateOnBlur={() =>
                    validateAgeRange(ageRange, "Age range cannot be blank")
                }
                disabled={isFormSubmitting}
            />
            <Select
                label={"English Level"}
                headerLabel="Select your english fluency"
                value={englishLevel}
                onSelect={(englishLevel) => {
                    setEnglishLevel(englishLevel);
                    validateEnglishLevel(englishLevel, "");
                }}
                options={englishLevels}
                error={isEnglishLevelError}
                hintMessage={englishLevelError}
                validateOnBlur={() =>
                    validateEnglishLevel(
                        englishLevel,
                        "English level cannot be blank"
                    )
                }
                disabled={isFormSubmitting}
            />
            <Select
                label={"Equipment"}
                headerLabel="Do you have a Laptop?"
                value={haveLaptop}
                onSelect={(haveLaptop) => {
                    setHaveLaptop(haveLaptop);
                    validateHaveLaptop(haveLaptop, "");
                }}
                options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" }
                ]}
                error={isHaveLoptopError}
                hintMessage={haveLaptopError}
                validateOnBlur={() =>
                    validateHaveLaptop(haveLaptop, "Oh, you missed this field")
                }
                disabled={isFormSubmitting}
            />
            <StatementInput
                type="textarea"
                label="Message (Optional)"
                placeholder="Your message"
                value={statement}
                onChange={setStatement}
                id="statementInput"
                disabled={isFormSubmitting}
            />
            <FormButton
                color="primary"
                variant="contained"
                size="medium"
                onClick={handleRegistration}
                disabled={isFormSubmitting}
            >
                {isFormSubmitting ? "...Registering" : "Register Me"}
            </FormButton>
            {(isFormSubmitError || isFormSubmitSuccess) && (
                <CardToast $isSuccess={isFormSubmitSuccess}>
                    <Typography variant="textS" weight="normal" align="left">
                        {isFormSubmitSuccess
                            ? "Yay, You are now part of MiniBootcamp!"
                            : "Ups.Problem! Email us at hello@turantech.com"}
                    </Typography>
                </CardToast>
            )}
        </RegistrationForm>
    );
};
