import { Dispatch, SetStateAction, useState } from "react";
import { OptionValue } from "./Select";

type UseSelectReturnType = [
    OptionValue,
    Dispatch<SetStateAction<OptionValue>>,
    boolean,
    string,
    (value: OptionValue, errorMessage: string) => void,
    boolean
];
export const useSelect = (initialValue: OptionValue): UseSelectReturnType => {
    const [value, setValue] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [isValidated, setIsValidated] = useState(false);

    const validateSelect = (value: OptionValue, errorMessage: string) => {
        let isValid = true;
        if (!value) isValid = false;
        setIsError(!isValid);
        setErrorMessage(isValid ? "" : errorMessage);
        setIsValidated(isValid);
    };

    return [
        value,
        setValue,
        isError,
        errorMessage,
        validateSelect,
        isValidated
    ];
};
