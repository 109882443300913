export const cleanupNumberInput = (input: string) => {
    return input.replace(/[^\d]/g, "");
};

export const formatPhoneNumber = (input: string) => {
    if (!input.length) return "";
    if (input.length > 0 && input.length <= 3) {
        return `(${input})`;
    } else if (input.length < 6) {
        return `(${input.slice(0, 3)}) ${input.slice(3)}`;
    } else if (input.length >= 6) {
        return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(
            6,
            10
        )}`;
    }
    return "";
};

export class EmailValidator {
    allowedUsernameChars: string[];
    allowedDomainNameChar: string;
    constructor() {
        this.allowedUsernameChars = [".", "_", "-"];
        this.allowedDomainNameChar = "-";
    }

    isAlphabetic(char: string) {
        return (
            char.toLowerCase().charCodeAt(0) <= 122 &&
            char.toLowerCase().charCodeAt(0) >= 97
        );
    }

    isNumeric(char: string) {
        return char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57;
    }

    validateUserName(username: string): boolean {
        const firstChar = username[0];
        const lastChar = username[username.length - 1];
        if (
            username.length > 20 ||
            username.length < 1 ||
            this.allowedUsernameChars.includes(firstChar) ||
            this.allowedUsernameChars.includes(lastChar) ||
            (!this.isAlphabetic(lastChar) && !this.isNumeric(lastChar))
        ) {
            return false;
        }

        for (let i = 0; i < username.length - 1; i++) {
            const current = username[i];
            const next = username[i + 1];

            if (
                this.allowedUsernameChars.includes(current) &&
                this.allowedUsernameChars.includes(next)
            ) {
                return false;
            }

            if (
                !this.allowedUsernameChars.includes(current) &&
                !this.isAlphabetic(current) &&
                !this.isNumeric(current)
            ) {
                return false;
            }
        }

        return true;
    }

    validateDomain(domain: string): boolean {
        const splitDomain = domain.split(".");
        if (splitDomain.length > 2) return false;
        const [name, tld] = splitDomain;

        if (!this.validateDomainName(name) || !this.validateTld(tld))
            return false;
        return true;
    }

    validateDomainName(name: string): boolean {
        if (!name) return false;
        const firstChar = name[0];
        const lastChar = name[name.length - 1];
        if (
            firstChar === this.allowedDomainNameChar ||
            lastChar === this.allowedDomainNameChar ||
            (!this.isAlphabetic(lastChar) && !this.isNumeric(lastChar))
        )
            return false;

        for (let i = 0; i < name.length - 1; i++) {
            const current = name[i];
            const next = name[i + 1];
            if (current === this.allowedDomainNameChar && current === next)
                return false;

            if (
                current !== this.allowedDomainNameChar &&
                !this.isAlphabetic(current) &&
                !this.isNumeric(current)
            )
                return false;
        }

        return true;
    }

    validateTld(tld: string): boolean {
        if (tld.length < 2 || tld.length > 7) return false;
        for (let i = 0; i < tld.length; i++) {
            const char = tld[i];
            if (!this.isAlphabetic(char)) return false;
        }

        return true;
    }
    validate(email: string): boolean {
        const splitEmail = email.split("@");
        if (splitEmail.length !== 2) return false;

        const [username, domain] = splitEmail;

        if (!this.validateUserName(username) || !this.validateDomain(domain)) {
            return false;
        }
        return true;
    }
}
