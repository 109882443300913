import styled from "styled-components";
import { useState, useRef, useEffect, useCallback } from "react";
import { SelectBody } from "./SelectBody";
import { SelectHeader } from "./SelectHeader";
import { SelectOption, SelectOptionText } from "./SelectOption";
import { InputLabel, InputHint } from "../Inputs/InputBase";
import { Input } from "../Inputs/Input";
import { getFilteredOptions } from "./utils";

const SearchInput = styled(Input)`
    margin-bottom: var(--spacing-2);
`;
const SelectBase = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
`;

export type OptionValue = string | number;
export interface Option {
    value: OptionValue;
    label: string;
}
interface SelectProps {
    options: Option[];
    disabled?: boolean;
    error?: boolean;
    label?: string;
    headerLabel: string;
    onSelect: (value: OptionValue) => void;
    validateOnBlur: () => void;
    value: OptionValue;
    className?: string;
    hintMessage?: string;
    searchable?: boolean;
}
const handleOutsideClick = (
    event: Event,
    ref: any,
    setExpanded: (arg: boolean) => void,
    validateOnBlur: () => void
) => {
    if (!ref.current.contains(event.target)) {
        setExpanded(false);
        validateOnBlur();
    }
};

export const Select: React.FC<SelectProps> = ({
    options,
    disabled,
    error,
    label,
    headerLabel,
    onSelect,
    validateOnBlur,
    value,
    hintMessage,
    className,
    searchable,
}) => {
    const [search, setSearch] = useState("");
    const selectRef = useRef<HTMLDivElement>(null);
    const selectHeaderRef = useRef<HTMLButtonElement>(null);

    const finalHeaderLabel = value
        ? options.filter((option) => option.value === value)[0]?.label
        : headerLabel;
    const [expanded, setExpanded] = useState(false);

    const handleMouseDown = useCallback(
        (e: Event) => {
            handleOutsideClick(e, selectRef, setExpanded, validateOnBlur);
        },
        [validateOnBlur]
    );

    useEffect(() => {
        if (expanded) {
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [selectRef, expanded, handleMouseDown]);

    const handleOnSelect = (option: Option) => {
        onSelect(option.value);
        selectHeaderRef?.current?.focus();
        setExpanded(false);
        setSearch("");
    };

    const handleOnClick = () => {
        selectHeaderRef?.current?.focus();
        setExpanded(!expanded);
    };

    return (
        <SelectBase className={className} ref={selectRef}>
            <InputLabel error={error}>{label}</InputLabel>
            <SelectHeader
                disabled={disabled}
                error={error}
                label={finalHeaderLabel}
                onClick={handleOnClick}
                hasSelection={headerLabel !== finalHeaderLabel}
                expanded={expanded}
                ref={selectHeaderRef}
            />
            <InputHint error={error}>{hintMessage}</InputHint>

            {expanded && (
                <SelectBody>
                    {searchable && (
                        <SearchInput
                            type="text"
                            value={search}
                            onChange={(value) => setSearch(value)}
                            placeholder="Search"
                        />
                    )}
                    {getFilteredOptions(options, search).map((option) => {
                        return (
                            <SelectOption
                                key={option.value}
                                onSelect={handleOnSelect}
                                option={option}
                                selectedValue={value}
                            >
                                <SelectOptionText>
                                    {option.label}
                                </SelectOptionText>
                            </SelectOption>
                        );
                    })}
                </SelectBody>
            )}
        </SelectBase>
    );
};
