import styled from "styled-components";

export const IconRoundBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-100);
    border: 0.5rem solid var(--primary-50);
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    user-select: none;
`;

export const IconSquareBackground = styled.div<{
    large?: boolean;
    variant: "contained" | "outlined";
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    height: ${(props) => (props.large ? "6rem" : "5.4rem")};
    width: ${(props) => (props.large ? "6rem" : "5.4rem")};
    background-color: ${(props) =>
        props.variant === "contained" ? "var(--primary-600)" : "var(--white)"};
    color: ${(props) =>
        props.variant === "contained" ? "var(--white)" : "var(--primary-600)"};

    border: 2px solid var(--primary-600);
    user-select: none;
`;
