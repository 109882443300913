import styled from "styled-components";
import { useState } from "react";
import hero from "../../../assets/images/hero.jpg";
import { Container, HeaderBase, CalendlyPopup } from "../../../components";
import { Button } from "../../../../design-system";

const HeaderContent = styled(Container)`
    display: flex;
    gap: var(--spacing-16);
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 60em) {
        flex-direction: column;
    }
`;

const HeroImageWrapper = styled.div`
    flex-basis: 50%;
    flex-shrink: 0;
`;
const HeroImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 0 6.4rem;
`;

const HeroHoneypot = styled.div`
    flex-basis: 50%;
    flex-shrink: 1;
`;

const HeroHeading = styled.h1`
    font-size: var(--font-size-9);
    line-height: var(--line-height-9);
    color: var(--gray-900);
    font-weight: var(--font-weight-500);
    margin-bottom: var(--spacing-6);
`;

const HeroSubtext = styled.p`
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
    font-weight: var(--font-weight-400);
    color: var(--gray-600);
    margin-bottom: var(--spacing-12);
`;

const HeroCTAs = styled.div`
    display: flex;
    gap: var(--spacing-3);

    @media screen and (max-width: 30em) {
        flex-direction: column;

        Button,
        a {
            width: 100%;
        }
    }
`;

const Header = () => {
    const [showCalendly, setShowCalendly] = useState(false);
    return (
        <HeaderBase>
            <HeaderContent>
                <HeroHoneypot>
                    <HeroHeading>
                        Become a Software Engineer in 24 weeks
                    </HeroHeading>
                    <HeroSubtext>
                        Level up your life, career, and finance with our Online
                        and Onsite coding programs.
                    </HeroSubtext>

                    <HeroCTAs>
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                            renderAsLink
                            navigateTo="#howitworks"
                        >
                            How It Works?
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() => setShowCalendly(true)}
                        >
                            Need Consultation?
                        </Button>
                        <CalendlyPopup
                            url="https://calendly.com/turantech/15?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=7f56d9"
                            onClose={() => setShowCalendly(false)}
                            open={showCalendly}
                        />
                    </HeroCTAs>
                </HeroHoneypot>

                <HeroImageWrapper>
                    <HeroImage src={hero} />
                </HeroImageWrapper>
            </HeaderContent>
        </HeaderBase>
    );
};

export { Header };
