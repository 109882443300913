import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
    Container,
    SectionHeading,
    SectionName,
    SectionBase,
    CheckmarkItem,
} from "../../../components";
import { Typography } from "../../../../design-system";
import crmCalendar from "../../../assets/project-screens/crm-calendar.png";
import crmCustomer from "../../../assets/project-screens/crm-customer.png";
import crmMessaging from "../../../assets/project-screens/crm-messaging.png";
import crmKanban from "../../../assets/project-screens/crm-kanban.png";
import socialNetwork from "../../../assets/project-screens/social-network.png";
import devLogos from "../../../assets/dev-logos/sprite.svg";

const technologies = [
    {
        label: "Apollo",
        id: "apollo",
    },
    {
        label: "CSS",
        id: "css",
    },
    {
        label: "Docker",
        id: "docker",
    },
    {
        label: "ExpressJS",
        id: "expressjs",
    },
    {
        label: "Git",
        id: "git",
    },
    {
        label: "GitHub",
        id: "github",
    },
    {
        label: "GraphQL",
        id: "graphql",
    },
    {
        label: "HTML",
        id: "html",
    },
    {
        label: "JavaScript",
        id: "javascript",
    },
    {
        label: "Jest",
        id: "jestjs",
    },
    {
        label: "MongoDB",
        id: "mongo",
    },
    {
        label: "MySQL",
        id: "mysql",
    },
    {
        label: "Netlify",
        id: "netlify",
    },
    {
        label: "NodeJS",
        id: "node",
    },
    {
        label: "Prisma",
        id: "prismajs",
    },
    {
        label: "React",
        id: "react",
    },
    {
        label: "Redux",
        id: "redux",
    },
    {
        label: "Terminal",
        id: "terminal",
    },
    {
        label: "TypeScript",
        id: "typescript",
    },
];

const projectSamples = [
    { imgSrc: crmCustomer, caption: "Customer Management Application" },
    { imgSrc: crmKanban, caption: "Kanban Board Application" },
    {
        imgSrc: crmCalendar,
        caption: "Scheduling Application",
    },
    {
        imgSrc: crmMessaging,
        caption: "Messaging Application",
    },
    {
        imgSrc: socialNetwork,
        caption: "Social Media Application",
    },
];

const modules = [
    "Frontend",
    "Deployment",
    "Backend",
    "Systems Design",
    "Algorithms",
    "Data Structures",
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Section = styled(SectionBase)`
    background-color: var(--red-50);
`;

const SectionContent = styled(Container)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: var(--spacing-16);
`;

const CurriculumParagraph = styled(Typography)`
    margin-bottom: var(--spacing-8);
`;

const ProjectImages = styled.div`
    background-color: var(--red-50);

    @media screen and (max-width: 60em) {
        grid-column: 1 /3;
    }
`;
const ProjectFigure = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
const ProjectImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    padding-left: 1px;
`;

const CurriculumModules = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-4);

    img {
        height: 1.8rem;
        width: 1.8rem;
    }
    @media screen and (max-width: 60em) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 40em) {
        grid-template-columns: max-content 1fr;
    }

    @media screen and (max-width: 22.5em) {
        grid-template-columns: 1fr;
    }
`;

const TechnologiesLogos = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-8);

    grid-row: 2/3;
    grid-column: 1/3;

    svg {
        height: 3rem;
        width: 3rem;
        fill: var(--gray-600);
    }
`;

const TechnologyLogo = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
`;

const CurriculumText = styled.div`
    @media screen and (max-width: 60em) {
        grid-column: 1 /3;
    }
`;

// const LearnMoreButton = styled(Button)`
//     grid-column: 1/3;
//     justify-self: center;
// `;

const Curriculum = () => {
    return (
        <Section id="curriculum">
            <SectionContent>
                <CurriculumText>
                    <SectionName align="left">Curriculum</SectionName>
                    <SectionHeading align="left">
                        Powered by Experts
                    </SectionHeading>
                    <CurriculumParagraph
                        align="left"
                        weight="normal"
                        variant="textL"
                    >
                        Our curriculum, shaped under the counsel of our software
                        engineering advisory board, is fine-tuned for industry
                        applicability. Rather than focusing on rudimentary
                        projects, we guide you through the development of
                        sophisticated, visually appealing applications and
                        websites.
                    </CurriculumParagraph>
                    <CurriculumModules>
                        {modules.map((module, idx) => {
                            return <CheckmarkItem key={idx} text={module} />;
                        })}
                    </CurriculumModules>
                </CurriculumText>
                <ProjectImages>
                    <AutoPlaySwipeableViews autoPlay={true}>
                        {projectSamples.map((projectSample, idx) => {
                            return (
                                <ProjectFigure key={idx}>
                                    <ProjectImage
                                        src={projectSample.imgSrc}
                                        alt={projectSample.caption}
                                    />
                                    <figcaption>
                                        <Typography
                                            variant="textL"
                                            weight="normal"
                                            align="center"
                                        >
                                            {projectSample.caption}
                                        </Typography>
                                    </figcaption>
                                </ProjectFigure>
                            );
                        })}
                    </AutoPlaySwipeableViews>
                </ProjectImages>
                <TechnologiesLogos>
                    {technologies.map((tech) => {
                        return (
                            <TechnologyLogo key={tech.id}>
                                <svg>
                                    <use xlinkHref={`${devLogos}#${tech.id}`} />
                                </svg>
                                <Typography
                                    variant="textS"
                                    weight="medium"
                                    align="left"
                                >
                                    {tech.label}
                                </Typography>
                            </TechnologyLogo>
                        );
                    })}
                </TechnologiesLogos>
                {/* <LearnMoreButton
                    variant="contained"
                    color="primary"
                    size="medium"
                >
                    Learn More About It
                </LearnMoreButton> */}
            </SectionContent>
        </Section>
    );
};

export { Curriculum };
