import styled from "styled-components";

const SelectBodyBase = styled.ul`
    position: absolute;
    top: 7.8rem;
    padding: 0.6rem;
    max-height: 24rem;
    width: 100%;
    border-radius: 0.8rem;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    z-index: 1;

    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0.8rem;
        border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0.8rem;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-200);
        border-radius: 0.8rem;
    }
    // for IE
    body {
        scrollbar-width: $width;
        scrollbar-face-color: #c1cbd0;
        scrollbar-track-color: #deecf7;
    }
`;

interface SelectBodyProps {
    children: React.ReactNode;
}
export const SelectBody: React.FC<SelectBodyProps> = ({ children }) => {
    return <SelectBodyBase>{children}</SelectBodyBase>;
};
