import { forwardRef } from "react";
import styled, { css } from "styled-components";

interface SelectHeaderBaseProps {
    $error?: boolean;
    $hasSelection: boolean;
    $expanded: boolean;
}

const SelectHeaderBase = styled.button<SelectHeaderBaseProps>`
    height: 4.4rem;
    width: 100%;
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: 0.8rem;
    padding: 0 1.4rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-3);

    font-family: "Maven Pro", sans-serif;
    font-size: 16px;
    color: ${(props) =>
        props.$hasSelection ? "var(--gray-900)" : "var(--gray-500)"};

    svg {
        transition: transform 0.2s;
    }

    &:disabled {
        color: var(--gray-500);
        background-color: var(--gray-50);
    }

    ${(props) =>
        !props.$error &&
        css`
            &:hover:not(:disabled, :focus, :active) {
                border: 1px solid var(--primary-600);
            }
            &:focus:not(:disabled),
            &:active:not(:disabled) {
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
                    0px 0px 0px 4px var(--primary-100);
                border: 1px solid var(--primary-300);
            }
        `}

    ${(props) =>
        props.$expanded &&
        css`
            svg {
                transform: rotate(180deg);
            }
        `}

    ${(props) =>
        props.$error &&
        css`
            border: 1px solid var(--red-300);

            &:focus {
                box-shadow: 0px 0px 0px 4px var(--red-100);
                border: 1px solid var(--red-300);
            }

            &:hover:not(:disabled, :active, :focus) {
                border: 1px solid var(--red-300);
            }

            &:disabled {
                border: 1px solid var(--gray-300);
            }
        `}
`;

interface SelectHeaderProps {
    error?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    label: string;
    hasSelection: boolean;
    expanded: boolean;
}
export const SelectHeader = forwardRef<HTMLButtonElement, SelectHeaderProps>(
    ({ error, onClick, disabled, label, hasSelection, expanded }, ref: any) => {
        return (
            <SelectHeaderBase
                disabled={disabled}
                $error={error}
                $expanded={expanded}
                onClick={onClick}
                $hasSelection={hasSelection}
                ref={ref}
            >
                {label}
                <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 1.5L6 6.5L11 1.5"
                        stroke="#667085"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </SelectHeaderBase>
        );
    }
);
