import { FC } from "react";
import { TopNav, TopNavLink, TopNavHashLink } from "../../components";

const NavLinks: FC<{ closeMenu?: () => void }> = ({ closeMenu }) => {
    return (
        <>
            <li>
                <TopNavHashLink to="/">Main</TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#howitworks">
                    How It Works
                </TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#curriculum">Curriculum</TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#programs">Programs</TopNavHashLink>
            </li>

            <li>
                <TopNavLink to="../contact">Contact</TopNavLink>
            </li>
            <li>
                <TopNavLink to="" onClick={closeMenu}>
                    Application
                </TopNavLink>
            </li>
        </>
    );
};
export const ApplicationTopNav = () => {
    return (
        <TopNav mobileNavLinks={<NavLinks />}>
            <NavLinks />
        </TopNav>
    );
};
