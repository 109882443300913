import styled from "styled-components";
import { FooterBase, Container } from "../../../components";
import { Typography, Logo } from "../../../../design-system";
import facebook from "../../../assets/icons/facebook.svg";
import instagram from "../../../assets/icons/instagram.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
import github from "../../../assets/icons/github.svg";

const SocialMediaIcons = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-6);

    img {
        height: 1.8rem;
        width: 1.8rem;
    }
`;

const FooterContent = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 60em) {
        flex-direction: column;
        row-gap: var(--spacing-5);
    }
`;

const CopyRightText = styled(Typography)`
    color: var(--gray-300);
`;

const date = new Date();
const currentYear = date.getFullYear();

export const Footer = () => {
    return (
        <FooterBase>
            <FooterContent>
                <Logo variant="white" />
                <CopyRightText variant="textM" weight="normal" align="center">
                    &copy; {currentYear} Turan Institute Of Technology
                </CopyRightText>
                <SocialMediaIcons>
                    <img src={github} alt="Github" />
                    <img src={linkedin} alt="LinkedIn" />
                    <img src={facebook} alt="Facebook" />
                    <img src={instagram} alt="Instagram" />
                </SocialMediaIcons>
            </FooterContent>
        </FooterBase>
    );
};
