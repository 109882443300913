import { FC } from "react";
import { Button } from "../../../design-system";
import { TopNav, TopNavLink, TopNavHashLink } from "../../components";

const NavLinks: FC<{ closeMenu?: () => void }> = ({ closeMenu }) => {
    return (
        <>
            <li>
                <TopNavHashLink to="../">Main</TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#howitworks">
                    How It Works
                </TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#curriculum">Curriculum</TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="../#programs">Programs</TopNavHashLink>
            </li>
            <li>
                <TopNavLink to="">Contact</TopNavLink>
            </li>
            <li>
                <Button variant="contained" renderAsLink navigateTo="../apply">
                    Apply Now
                </Button>
            </li>
        </>
    );
};

export const ContactTopNav = () => {
    return (
        <TopNav mobileNavLinks={<NavLinks />}>
            <NavLinks />
        </TopNav>
    );
};
