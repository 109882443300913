import { PopupModal } from "react-calendly";

interface Props {
    open: boolean;
    onClose: () => void;
    url: string;
}

export const CalendlyPopup: React.FC<Props> = ({ open, onClose, url }) => {
    return (
        <PopupModal
            open={open}
            url={url}
            onModalClose={onClose}
            rootElement={document.getElementById("root") as HTMLElement}
        />
    );
};
