import chatWhite from "../assets/icons/chat-white.svg";
import decision from "../assets/icons/decision.svg";
import keyboard from "../assets/icons/keyboard.svg";
import brackets from "../assets/icons/brackets.svg";

export const ADMISSION_STEPS = [
    {
        step: 1,
        name: "Application",
        imgSrc: keyboard,
        description:
            "Tell us about your background and why you’re excited about attending TuranTech. This should take about 5-7 minutes to complete.",
    },
    {
        step: 2,
        name: "Let's chat",
        imgSrc: chatWhite,
        description:
            "This step helps us learn more about you, your ability to learn quickly, and your ability to work hard in our course.",
    },
    {
        step: 3,
        name: "Finish prep-work",
        imgSrc: brackets,
        description:
            "To succeed in the program, you should familiarize yourself with the tools and beginner-friendly learning materials we provide before the first day of the program.",
    },

    {
        step: 4,
        name: "Get Accepted",
        imgSrc: decision,
        description:
            "Upon successfully finishing the prep-work, your Admissions Specialist will officially accept you so you can enroll.",
    },
];

export const MINI_BOOTCAMP_STUDY_PLAN = [
    {
        number: 1,
        name: "Warm up",
        topics: [
            "Intro and Machine setup",
            "Intro to Request-Response cycle",
            "HTML | Blog Website Part-1",
            "HTML | Blog Website Part-2",
            "CSS | Blog Website Part-3",
            "Code Formatting",
        ],
    },
    {
        number: 2,
        name: "Speed up",
        topics: [
            "CSS | Blog Website Part-4",
            "CSS | Blog Website Part-4 Extra",
            "CSS | Margins, Paddins, Box-Sizing",
            "CSS | Blog Website Part-5",
            "HTML/CSS | Block and Inline Elements",
        ],
    },
    {
        number: 3,
        name: "Run",
        topics: [
            "Restaurant Website | Intro",
            "Restaurant Website | Setup",
            "Restaurant Website | Header",
            "Restaurant Website | Hero",
            "Restaurant Website | Menu ",
            "Restaurant Website | Reservation ",
        ],
    },

    {
        number: 4,
        name: "Fit",
        topics: [
            "Restaurant Website | Chefs",
            "Restaurant Website | Testimonals",
            "Restaurant Website | Hours",
            "Restaurant Website | Footer",
            "Restaurant Website | Deployment",
            "Restaurant Website | Assignment",
        ],
    },
];
