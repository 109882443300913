import styled from "styled-components";
import { useState } from "react";
import {
    Card,
    CardToast,
    Typography,
    Button,
    Input,
    useName,
    usePhoneNumber,
    useTextArea,
    useEmailInput,
} from "../../../design-system";

import {
    SectionBase,
    SectionName,
    SectionHeading,
    Container,
    SectionSubHeading,
} from "../../components";
import { ContactTopNav } from "./ContactTopNav";

import { Mailer } from "../../api";
import atSign from "../../assets/icons/at-sign.svg";
import chat from "../../assets/icons/chat.svg";
import location from "../../assets/icons/location.svg";
import phone from "../../assets/icons/phone.svg";

const contacts = [
    {
        icon: location,
        alt: "Location",
        heading: "Office",
        description: "We are in Dumbo, Brooklyn.",
        detail: "175 Pearl street, Brooklyn, NY 11201",
        link: "https://goo.gl/maps/gMZdCnNnBKvhkqDD8",
    },
    {
        icon: phone,
        alt: "Phone",
        heading: "Call us",
        description: "Mon-Thurs from 11am to 4pm EST",
        detail: "347-567-2828",
        link: "tel:+13475672828",
    },
    {
        icon: atSign,
        alt: "Email",
        heading: "Drop a line",
        description: "We respond within 24 hours.",
        detail: "hello@turantech.com",
        link: "mailto:hello@turantech.com",
    },
    {
        icon: chat,
        alt: "Chat",
        heading: "Live chat",
        description: "Let's chat. We are here to help.",
        detail: "Start chatting",
        link: "#chatraChatExpanded",
    },
];

const ContactWays = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-16);

    @media screen and (max-width: 60em) {
        grid-template-columns: 1fr;
    }
`;

const ContactFormCard = styled(Card)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    align-content: start;
    column-gap: var(--spacing-4);
    row-gap: var(--spacing-6);
    align-items: start;
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-10);

    position: relative;
`;

const ContactCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-16);
    height: max-content;

    @media screen and (max-width: 60em) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 50em) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 30em) {
        grid-row: 2/3;
    }
`;

const ContactCard = styled.div``;

const ContactCardIcon = styled.img`
    margin-bottom: var(--spacing-5);
`;

const ContactDetail = styled.a`
    display: block;
    color: var(--primary-700);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    font-weight: var(--font-weight-600);
    margin-top: var(--spacing-5);
`;

const FirstNameInput = styled(Input)`
    @media screen and (max-width: 30em) {
        grid-column: 1/3;
    }
`;

const LastNameInput = styled(Input)`
    @media screen and (max-width: 30em) {
        grid-column: 1/3;
    }
`;

// 640-960
const EmailInput = styled(Input)`
    @media screen and (max-width: 65em) {
        grid-column: 1/3;
    }

    @media screen and (min-width: 40em) and (max-width: 60em) {
        grid-column: 1/2;
    }
    @media screen and (max-width: 40em) {
        grid-column: 1/3;
    }
`;
const PhoneInput = styled(Input)`
    @media screen and (max-width: 65em) {
        grid-column: 1/3;
    }
    @media screen and (min-width: 40em) and (max-width: 60em) {
        grid-column: 2/3;
    }
    @media screen and (max-width: 40em) {
        grid-column: 1/3;
    }
`;

const MessageInput = styled(Input)`
    grid-column: 1/3;
`;
const FormButton = styled(Button)`
    grid-column: 2/3;
    justify-self: end;
`;

const mailer = new Mailer();
export const Contact = () => {
    const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
    const [isFormSubmitError, setIsFormSubmitError] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [
        firstName,
        setFirstName,
        isNameError,
        nameErrorMessage,
        validateName,
        isNameValidated,
    ] = useName("");

    const [
        lastName,
        setLastName,
        isLastNameError,
        lastNameErrorMessage,
        validateLastName,
        isLastNameValidated,
    ] = useName("");

    const [
        phoneNumber,
        setPhoneNumber,
        isPhoneNumberError,
        phoneNumberErrorMessage,
        validatePhoneNumber,
        isPhoneNumberValidated,
    ] = usePhoneNumber("");
    const [
        message,
        setMessage,
        isTextAreaError,
        textAreaErrorMessage,
        validateTextArea,
        isTextareaValidated,
    ] = useTextArea("");

    const [
        email,
        setEmail,
        isEmailError,
        emailErrorMessage,
        validateEmail,
        isEmailValidated,
    ] = useEmailInput("");

    const isFormSubmittable =
        isNameValidated &&
        isLastNameValidated &&
        isPhoneNumberValidated &&
        isTextareaValidated &&
        isEmailValidated;

    const resetFormData = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
    };

    const handleFormSubmit = async () => {
        if (
            isFormSubmittable &&
            !isFormSubmitSuccess &&
            !isFormSubmitError &&
            !isFormSubmitting
        ) {
            try {
                setIsFormSubmitting(true);
                await mailer.sendMessage({
                    visitorFirstName: firstName,
                    visitorLastTime: lastName,
                    visitorEmail: email,
                    visitorPhoneNumber: phoneNumber,
                    visitorMessage: message,
                });

                resetFormData();
                setIsFormSubmitSuccess(true);
                setIsFormSubmitting(false);

                setTimeout(() => {
                    setIsFormSubmitSuccess(false);
                }, 10000);
            } catch (error) {
                resetFormData();
                setIsFormSubmitError(true);
                setIsFormSubmitting(false);

                setTimeout(() => {
                    setIsFormSubmitError(false);
                }, 10000);
            }
        } else {
            validateEmail();
            validatePhoneNumber();
            validateName();
            validateLastName();
            validateTextArea();
            setIsFormSubmitError(false);
            setIsFormSubmitSuccess(false);
        }
    };

    return (
        <>
            <ContactTopNav />
            <SectionBase id="#contact">
                <Container>
                    <SectionName align="left">Contact Us</SectionName>
                    <SectionHeading align="left">
                        We’d love to hear from you
                    </SectionHeading>
                    <SectionSubHeading align="left">
                        Our friendly team is always here to chat.
                    </SectionSubHeading>
                    <ContactWays>
                        <ContactCards>
                            {contacts.map((contact) => {
                                return (
                                    <ContactCard key={contact.alt}>
                                        <ContactCardIcon
                                            src={contact.icon}
                                            alt={contact.alt}
                                        />
                                        <Typography
                                            variant="textXL"
                                            align="left"
                                            weight="semibold"
                                        >
                                            {contact.heading}
                                        </Typography>
                                        <Typography
                                            variant="textM"
                                            align="left"
                                            weight="normal"
                                        >
                                            {contact.description}
                                        </Typography>
                                        <ContactDetail href={contact.link}>
                                            {contact.detail}
                                        </ContactDetail>
                                    </ContactCard>
                                );
                            })}
                        </ContactCards>

                        <ContactFormCard>
                            <FirstNameInput
                                label="First Name"
                                placeholder="First Name"
                                value={firstName}
                                onChange={setFirstName}
                                error={isNameError}
                                hintMessage={nameErrorMessage}
                                onBlur={validateName}
                                disabled={isFormSubmitting}
                                id="firstName"
                            />
                            <LastNameInput
                                label="Last Name"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={setLastName}
                                error={isLastNameError}
                                hintMessage={lastNameErrorMessage}
                                onBlur={validateLastName}
                                disabled={isFormSubmitting}
                                id={"lastName"}
                            />
                            <EmailInput
                                type="email"
                                label="Your Email"
                                placeholder="you@example.com"
                                value={email}
                                onChange={setEmail}
                                error={isEmailError}
                                hintMessage={emailErrorMessage}
                                onBlur={validateEmail}
                                disabled={isFormSubmitting}
                                id="emailAddress"
                            />
                            <PhoneInput
                                type="tel"
                                label="Phone Number"
                                placeholder="(123) 456-7890"
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                                error={isPhoneNumberError}
                                hintMessage={phoneNumberErrorMessage}
                                onBlur={validatePhoneNumber}
                                disabled={isFormSubmitting}
                                id="phoneNumber"
                            />
                            <MessageInput
                                type="textarea"
                                label="Message"
                                placeholder="Your message "
                                value={message}
                                onChange={setMessage}
                                error={isTextAreaError}
                                hintMessage={textAreaErrorMessage}
                                onBlur={validateTextArea}
                                disabled={isFormSubmitting}
                                id="message"
                            />
                            <FormButton
                                size="medium"
                                variant="contained"
                                onClick={handleFormSubmit}
                                disabled={isFormSubmitting}
                            >
                                {isFormSubmitting
                                    ? "...Sending"
                                    : "Send Message"}
                            </FormButton>
                            {(isFormSubmitError || isFormSubmitSuccess) && (
                                <CardToast $isSuccess={isFormSubmitSuccess}>
                                    <Typography
                                        variant="textS"
                                        weight="normal"
                                        align="left"
                                    >
                                        {isFormSubmitSuccess
                                            ? "We have received your message!"
                                            : "Ups.Problem! Email us at hello@turantech.com"}
                                    </Typography>
                                </CardToast>
                            )}
                        </ContactFormCard>
                    </ContactWays>
                </Container>
            </SectionBase>
        </>
    );
    // 100 => 300 50 => 100
};
