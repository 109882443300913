import styled from "styled-components";
import {
    SectionBase,
    Container,
    SectionName,
    SectionHeading,
    SectionSubHeading,
} from "../../components";
import { MiniBootcampStudyPlan } from "./MiniBoocampStudyPlan";
import { MiniBootcampTopNav } from "./MiniBoocampTopNav";
import { MiniBootcampRegistration } from "./MiniBootcampRegistration";

const SectionContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-16);
    row-gap: var(--spacing-16);

    @media screen and (max-width: 90em) {
        grid-template-columns: 1fr;
    }
`;

export const MiniBootcamp = () => {
    return (
        <>
            <MiniBootcampTopNav />
            <SectionBase>
                <Container>
                    <SectionName align="left">Starting Today</SectionName>
                    <SectionHeading align="left">
                        Free Mini Bootcamp
                    </SectionHeading>
                    <SectionSubHeading align="left">
                        Spend your 4 weeks with us to find out if Programming
                        fits you!
                    </SectionSubHeading>
                    <SectionContent>
                        <MiniBootcampStudyPlan />
                        <MiniBootcampRegistration />
                    </SectionContent>
                </Container>
            </SectionBase>
        </>
    );
};

/* 
First Name
Last Name
Email
Phone Number
Age Range
Current Occupation
Current Industry
Your state
Do you have a loptop
 */
