import { FC } from "react";
import styled, { css } from "styled-components";
import { InputLabel, InputHint } from "../Inputs/InputBase";

interface CheckboxProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    indeterminate?: boolean;
    id?: string;
    label?: string;
    error: boolean;
    hintMessage?: string;
}

interface CheckboxMaskProps {
    error?: boolean;
}

const CheckboxLabel = styled(InputLabel)<{ error?: boolean }>`
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 2.9rem;
`;
const CheckboxBase = styled.input`
    position: absolute;
    height: 2rem;
    width: 2rem;
`;

const CheckboxMask = styled.div<CheckboxMaskProps>`
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    height: 2rem;
    width: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    /* When Checkbox is not checked */
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius-4);
    background-color: var(--white);

    ${(props) =>
        props.error &&
        css`
            border: 1px solid var(--red-300);
        `}

    /* When Checkbox :disabled: Checked and Not Checked */
    ${CheckboxBase}:disabled ~ &, 
    ${CheckboxBase}:checked:disabled ~ & {
        pointer-events: none;
        border-color: var(--gray-300);
        background-color: var(--gray-100);
    }

    /* When Checkbox :hover */
    &:hover {
        border-color: var(--primary-600);
        background-color: var(--primary-100);
    }

    /* When Checkbox :focus */
    ${CheckboxBase}:focus ~ & {
        box-shadow: 0px 0px 0px 4px var(--primary-100);
        border: 1px solid var(--primary-300);
    }

    /* When Checkbox :focus and :hover */
    ${CheckboxBase}:focus ~ &:hover {
        background-color: var(--white);
    }

    /* When Checkbox :checked */
    ${CheckboxBase}:checked ~ & {
        border-color: var(--primary-600);
        background-color: var(--primary-50);
    }

    /* When Checkbox :checked and :hover */
    ${CheckboxBase}:checked ~ &:hover {
        background-color: var(--primary-100);
    }

    /* When Checkbox :checked and :focus */
    ${CheckboxBase}:checked:focus ~ & {
        border-color: var(--primary-600);
    }

    /* When Checkbox :checked, :focus and :hover */
    ${CheckboxBase}:checked:focus ~ &:hover {
        background-color: var(--primary-50);
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
`;

export const Checkbox: FC<CheckboxProps> = ({
    checked,
    onChange,
    label,
    disabled,
    indeterminate,
    id,
    error,
    hintMessage
}) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked);
    };
    return (
        <CheckboxContainer>
            <CheckboxLabel error={error}>
                {label}
                <CheckboxBase
                    type="checkbox"
                    checked={checked}
                    onChange={handleOnChange}
                    id={id}
                    disabled={disabled}
                />
                <CheckboxMask error={error}>
                    {checked && !indeterminate && (
                        <svg
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.6666 1.5L4.24998 7.91667L1.33331 5"
                                stroke={disabled ? "#D0D5DD" : "#7F56D9"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                    {checked && indeterminate && (
                        <svg
                            width="12"
                            height="2"
                            viewBox="0 0 12 2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.91669 1H10.0834"
                                stroke={disabled ? "#D0D5DD" : "#7F56D9"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </CheckboxMask>
            </CheckboxLabel>
            {error && <InputHint error={error}>{hintMessage}</InputHint>}
            {!error && hintMessage && <InputHint>{hintMessage}</InputHint>}
        </CheckboxContainer>
    );
};
