import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ApplicationTopNav } from "./ApplicationTopNav";
import {
    Card,
    CardToast,
    Typography,
    Button,
    Input,
    Select,
    useName,
    usePhoneNumber,
    useTextArea,
    useEmailInput,
    useSelect,
} from "../../../design-system";
import {
    SectionBase,
    Container,
    SectionName,
    SectionHeading,
    IconSquareBackground,
    SectionSubHeading,
} from "../../components";
import { ADMISSION_STEPS } from "../../constants";
import { Mailer } from "../../api";
import { getSelectLabel } from "../../../design-system/Select/utils";

const programs = [
    {
        label: "Frontend  Engineering",
        value: "frontend",
    },
    {
        label: "Fullstack  Engineering",
        value: "fullstack",
    },
];

const frontendCohorts = [
    {
        label: "January 21, 2024 - June 9, 2024",
        value: "January 21, 2024 - June 9, 2024",
    },
    {
        label: "March 4, 2024 - July 22, 2024",
        value: "March 4, 2024 - July 22, 2024",
    },
];

const fullstackCohorts = [
    {
        label: "January 21, 2024 - July 7, 2024",
        value: "January 21, 2024 - July 7, 2024",
    },
    {
        label: "March 4, 2024 - August 19, 2024",
        value: "March 4, 2024 - August 19, 2024",
    },
];

const locations = [
    {
        label: "Online",
        value: "online",
    },
    {
        label: "Brooklyn, NY",
        value: "brooklyn-newyork",
    },
];
const SectionContent = styled(Container)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content 1fr;
    column-gap: var(--spacing-16);
`;
const SectionHeader = styled.div`
    @media screen and (max-width: 60em) {
        grid-column: 1/3;
        grid-row: 1/2;
    }
`;

const ApplicationProcess = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-16);

    grid-column: 1/2;
    grid-row: 2/3;

    @media screen and (max-width: 60em) {
        grid-column: 1/3;
        grid-row: 3/4;
    }
`;

const ApplicationForm = styled(Card)`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    align-content: start;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-6);
    align-items: start;
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-10);
    height: max-content;

    grid-column: 2/3;
    grid-row: 1/3;

    @media screen and (max-width: 80em) {
        grid-column: 2/3;
        grid-row: 2/3;
        margin-bottom: var(--spacing-16);
    }

    @media screen and (max-width: 60em) {
        grid-column: 1/3;
        grid-row: 2/3;
        margin-bottom: var(--spacing-16);
    }
`;

const AdmissionsStepIcon = styled(IconSquareBackground)`
    margin-bottom: 2rem;
    font-size: var(--font-size-6);
`;

const AdmissionsStepHeading = styled(Typography)`
    margin-bottom: var(--spacing-2);
`;
const FirstNameInput = styled(Input)`
    @media screen and (max-width: 30em) {
        grid-column: 1/3;
    }
`;

const LastNameInput = styled(Input)`
    @media screen and (max-width: 30em) {
        grid-column: 1/3;
    }
`;
const EmailInput = styled(Input)`
    grid-column: 1/3;
`;
const PhoneInput = styled(Input)`
    grid-column: 1/3;
`;

const StatementInput = styled(Input)`
    grid-column: 1/3;
`;
const FormButton = styled(Button)`
    grid-column: 1/3;
    justify-self: end;
`;

const ProgramsSelect = styled(Select)`
    grid-column: 1/3;
`;

const LocationSelect = styled(Select)`
    grid-column: 1/3;
`;

const CohortSelect = styled(Select)`
    grid-column: 1/3;
`;

const mailer = new Mailer();
export const Application = () => {
    const [searchParams] = useSearchParams();
    const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
    const [isFormSubmitError, setIsFormSubmitError] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [
        firstName,
        setFirstName,
        isNameError,
        nameErrorMessage,
        validateName,
        isNameValidated,
    ] = useName("");

    const [
        lastName,
        setLastName,
        isLastNameError,
        lastNameErrorMessage,
        validateLastName,
        isLastNameValidated,
    ] = useName("");

    const [
        phoneNumber,
        setPhoneNumber,
        isPhoneNumberError,
        phoneNumberErrorMessage,
        validatePhoneNumber,
        isPhoneNumberValidated,
    ] = usePhoneNumber("");
    const [
        statement,
        setStatement,
        isStatementError,
        statementErrorMessage,
        validateStatement,
        isStatementValidated,
    ] = useTextArea("");

    const [
        email,
        setEmail,
        isEmailError,
        emailErrorMessage,
        validateEmail,
        isEmailValidated,
    ] = useEmailInput("");

    const [
        program,
        setProgram,
        isProgramError,
        programErrorMessage,
        validateProgram,
        isProgramValidated,
    ] = useSelect(searchParams.get("programType") || "");

    const [
        location,
        setLocation,
        isLocationError,
        locationErrorMessage,
        validateLocation,
        isLocationValidated,
    ] = useSelect("");

    const [
        cohort,
        setCohort,
        isCohortError,
        cohortErrorMessage,
        validateCohort,
        isCohortValidated,
    ] = useSelect("");

    useEffect(() => {
        setCohort("");
    }, [program, setCohort]);

    const isFormSubmittable =
        isNameValidated &&
        isLastNameValidated &&
        isPhoneNumberValidated &&
        isStatementValidated &&
        isEmailValidated &&
        isProgramValidated &&
        isLocationValidated &&
        isCohortValidated;

    const resetFormData = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setStatement("");
        setProgram("");
        setLocation("");
        setCohort("");
    };

    const handleApplicationSubmit = async () => {
        console.log(program, cohort);
        if (
            isFormSubmittable &&
            !isFormSubmitSuccess &&
            !isFormSubmitError &&
            !isFormSubmitting
        ) {
            try {
                setIsFormSubmitting(true);
                await mailer.submitApplication({
                    applicantFirstName: firstName,
                    applicantLastName: lastName,
                    applicantEmail: email,
                    applicantPhoneNumber: phoneNumber,
                    applicantProgramChoice: getSelectLabel(programs, program),
                    applicantLocationChoice: getSelectLabel(
                        locations,
                        location
                    ),
                    applicantCohortChoice: getSelectLabel(
                        program === "frontend"
                            ? frontendCohorts
                            : fullstackCohorts,
                        cohort
                    ),
                    applicantStatement: statement,
                });
                resetFormData();
                setIsFormSubmitSuccess(true);
                setIsFormSubmitting(false);

                setTimeout(() => {
                    setIsFormSubmitSuccess(false);
                }, 10000);
            } catch (error) {
                resetFormData();
                setIsFormSubmitError(true);
                setIsFormSubmitting(false);

                setTimeout(() => {
                    setIsFormSubmitError(false);
                }, 10000);
            }
        } else {
            validateName();
            validateLastName();
            validateEmail();
            validatePhoneNumber();
            validateProgram(program, "You forgot to select the program");
            validateLocation(location, "You forgot to select the location");
            validateCohort(cohort, "You forgot to select the cohort");
            validateStatement();
        }
    };

    return (
        <>
            <ApplicationTopNav />
            <SectionBase>
                <SectionContent>
                    <SectionHeader>
                        <SectionName align="left">
                            Application Process
                        </SectionName>
                        <SectionHeading align="left">
                            Based on your Aspiration
                        </SectionHeading>
                        <SectionSubHeading align="left">
                            You don't need any coding experience to be accepted!
                        </SectionSubHeading>
                    </SectionHeader>
                    <ApplicationProcess>
                        {ADMISSION_STEPS.map((step, idx) => {
                            return (
                                <div key={idx}>
                                    <AdmissionsStepIcon variant="contained">
                                        {step.step}
                                    </AdmissionsStepIcon>
                                    <AdmissionsStepHeading
                                        variant="textXL"
                                        weight="semibold"
                                        align="left"
                                    >
                                        {step.name}
                                    </AdmissionsStepHeading>
                                    <Typography
                                        variant="textM"
                                        weight="normal"
                                        align="left"
                                    >
                                        {step.description}
                                    </Typography>
                                </div>
                            );
                        })}
                    </ApplicationProcess>

                    <ApplicationForm>
                        <FirstNameInput
                            label="First Name"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(value) =>
                                setFirstName(value.trimStart())
                            }
                            error={isNameError}
                            hintMessage={nameErrorMessage}
                            onBlur={validateName}
                            id="firstName"
                            disabled={isFormSubmitting}
                        />
                        <LastNameInput
                            label="Last Name"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(value) => setLastName(value.trimStart())}
                            error={isLastNameError}
                            hintMessage={lastNameErrorMessage}
                            onBlur={validateLastName}
                            id="lastName"
                            disabled={isFormSubmitting}
                        />
                        <EmailInput
                            type="email"
                            label="Your Email"
                            placeholder="you@example.com"
                            value={email}
                            onChange={(value) => setEmail(value.trimStart())}
                            error={isEmailError}
                            hintMessage={emailErrorMessage}
                            onBlur={validateEmail}
                            id="emailAddress"
                            disabled={isFormSubmitting}
                        />
                        <PhoneInput
                            type="tel"
                            label="Phone Number"
                            placeholder="(123) 456-7890"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            error={isPhoneNumberError}
                            hintMessage={phoneNumberErrorMessage}
                            onBlur={validatePhoneNumber}
                            id="phoneNumber"
                            disabled={isFormSubmitting}
                        />
                        <ProgramsSelect
                            label={"Program type"}
                            headerLabel="Select the program"
                            value={program}
                            onSelect={(program) => {
                                setProgram(program);
                                validateProgram(program, "");
                            }}
                            options={programs}
                            error={isProgramError}
                            hintMessage={programErrorMessage}
                            validateOnBlur={() =>
                                validateProgram(
                                    program,
                                    "Program cannot be empty"
                                )
                            }
                            disabled={isFormSubmitting}
                            className="select-program"
                        />

                        {program ? (
                            <CohortSelect
                                label="Cohort"
                                headerLabel="Select Cohort"
                                value={cohort}
                                onSelect={(cohort) => {
                                    setCohort(cohort);
                                    validateCohort(cohort, "");
                                }}
                                options={
                                    program === "frontend"
                                        ? frontendCohorts
                                        : fullstackCohorts
                                }
                                error={isCohortError}
                                hintMessage={cohortErrorMessage}
                                validateOnBlur={() =>
                                    validateCohort(
                                        cohort,
                                        "Location cannot be empty"
                                    )
                                }
                                disabled={isFormSubmitting}
                                className="select-cohort"
                            />
                        ) : null}
                        <LocationSelect
                            label={"Location"}
                            headerLabel="Select Location"
                            value={location}
                            onSelect={(location) => {
                                setLocation(location);
                                validateLocation(location, "");
                            }}
                            options={locations}
                            error={isLocationError}
                            hintMessage={locationErrorMessage}
                            validateOnBlur={() =>
                                validateLocation(
                                    location,
                                    "Location cannot be empty"
                                )
                            }
                            disabled={isFormSubmitting}
                            className="select-location"
                        />

                        <StatementInput
                            type="textarea"
                            label="Statement"
                            placeholder="Why do you want to get into Tech?"
                            value={statement}
                            onChange={(value) =>
                                setStatement(value.trimStart())
                            }
                            error={isStatementError}
                            hintMessage={statementErrorMessage}
                            onBlur={validateStatement}
                            id="statementInput"
                            disabled={isFormSubmitting}
                        />

                        <FormButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            onClick={handleApplicationSubmit}
                            disabled={isFormSubmitting}
                        >
                            {isFormSubmitting
                                ? "...Submitting"
                                : "Submit Application"}
                        </FormButton>
                        {(isFormSubmitError || isFormSubmitSuccess) && (
                            <CardToast $isSuccess={isFormSubmitSuccess}>
                                <Typography
                                    variant="textS"
                                    weight="normal"
                                    align="left"
                                >
                                    {isFormSubmitSuccess
                                        ? "We have received your application!"
                                        : "Ups.Problem! Email us at hello@turantech.com"}
                                </Typography>
                            </CardToast>
                        )}
                    </ApplicationForm>
                </SectionContent>
            </SectionBase>
        </>
    );
};
