import styled from "styled-components";
import logo from "./logo.svg";
import logoWhite from "./logo-white.svg";
import symbol from "./symbol.svg";

interface LogoProps {
    variant?: "white" | "symbol";
    className?: string;
}

const LogoBase = styled.img<{ $isSymbol?: boolean }>`
    @media screen and (max-width: 30em) {
        height: ${(props) => (props.$isSymbol ? "3.1rem" : "4rem")};
        width: auto;
    }
`;

const dictironary = {
    white: logoWhite,
    symbol: symbol
};

export const Logo: React.FC<LogoProps> = ({ variant, className }) => {
    return (
        <LogoBase
            src={variant ? dictironary[variant] : logo}
            alt="TuranTech"
            $isSymbol={variant === "symbol"}
            className={className}
        />
    );
};
