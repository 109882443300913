import {
    createBrowserRouter,
    createRoutesFromElements,
    Route
} from "react-router-dom";

import App from "../App";
import {
    Contact,
    Main,
    Application,
    MiniBootcamp,
    Error
} from "../landing/pages";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<App />} errorElement={<Error />}>
                <Route index element={<Main />} />
                <Route path="contact" element={<Contact />}></Route>
                <Route path="apply" element={<Application />}></Route>
                <Route path="mini-bootcamp" element={<MiniBootcamp />}></Route>
            </Route>
        </>
    )
);
