import styled from "styled-components";
import { FC } from "react";
import checkmark from "../assets/icons/checkmark.svg";
import { Typography } from "../../design-system";

const CheckmarkItemBase = styled.li`
    display: flex;
    align-items: center;
    gap: var(--spacing-3);

    img {
        height: 1.8rem;
        width: 1.8rem;
    }
`;

export const CheckmarkItem: FC<{ text: string; className?: string }> = ({
    text,
    className
}) => {
    return (
        <CheckmarkItemBase className={className}>
            <img src={checkmark} alt="Checkmark" />
            <Typography variant="textL" weight="normal" align="left">
                {text}
            </Typography>
        </CheckmarkItemBase>
    );
};
