import styled from "styled-components";
import { MINI_BOOTCAMP_STUDY_PLAN } from "../../constants";
import { CheckmarkItem, IconSquareBackground } from "../../components";
import { Typography } from "../../../design-system";

const MiniBootcampStudyPlanBase = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-16);

    @media screen and (max-width: 40em) {
        grid-template-columns: 1fr;
    }
`;

const WeekIcon = styled(IconSquareBackground)`
    margin-bottom: 2rem;
    font-size: var(--font-size-6);
`;

const WeekHeading = styled(Typography)`
    margin-bottom: var(--spacing-2);
`;

const TopicItem = styled(CheckmarkItem)`
    &:not(:last-child) {
        margin-bottom: var(--spacing-2);
    }
`;

export const MiniBootcampStudyPlan = () => {
    return (
        <MiniBootcampStudyPlanBase>
            {MINI_BOOTCAMP_STUDY_PLAN.map((week, idx) => {
                return (
                    <div key={idx}>
                        <WeekIcon variant="contained">{week.number}</WeekIcon>
                        <WeekHeading
                            variant="textXL"
                            weight="semibold"
                            align="left"
                        >
                            {week.name}
                        </WeekHeading>
                        <ul>
                            {week.topics.map((topic, idx) => {
                                return <TopicItem text={topic} key={idx} />;
                            })}
                        </ul>
                    </div>
                );
            })}
        </MiniBootcampStudyPlanBase>
    );
};
