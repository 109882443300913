import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Logo, Button } from "../../../design-system";
import { MobileNav } from "./MobileNav";
import { Container, SectionRightAndLeftPadding } from "../index";
import burgerIcon from "../../assets/icons/burger.svg";

const TopNavBase = styled.nav`
    padding: 0 var(--spacing-24);
    height: 9rem;
    border-bottom: 1px solid var(--gray-100);
    background-color: var(--white);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    ${SectionRightAndLeftPadding}
`;

const TopNavContent = styled(Container)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

export const TopNavLinks = styled.ul`
    display: flex;
    gap: var(--spacing-8);
    align-items: center;

    @media screen and (max-width: 60em) {
        & {
            display: none;
        }
    }
`;

interface TopNavProps {
    children: React.ReactNode;
    mobileNavLinks: React.ReactElement;
}

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const MenuButton = styled(Button)`
    display: none;

    @media screen and (max-width: 60em) {
        & {
            display: flex;
        }
    }
    img {
        height: 2rem;
        width: 2rem;
    }
`;

export const TopNav: React.FC<TopNavProps> = ({ children, mobileNavLinks }) => {
    const [shouldShowMobileMenu, setShouldShowMobileMenu] = useState(false);
    const finalMobileNavLinks = React.cloneElement(mobileNavLinks, {
        closeMenu: () => setShouldShowMobileMenu(false)
    });
    return (
        <TopNavBase>
            <TopNavContent>
                <LogoLink to="/">
                    <Logo />
                </LogoLink>
                <TopNavLinks>{children}</TopNavLinks>
                <MenuButton
                    variant="contained"
                    color="primaryLight"
                    size="small"
                    onClick={() => setShouldShowMobileMenu(true)}
                >
                    <img src={burgerIcon} alt="" />
                </MenuButton>
            </TopNavContent>
            <MobileNav
                show={shouldShowMobileMenu}
                onClose={() => setShouldShowMobileMenu(false)}
            >
                {finalMobileNavLinks}
            </MobileNav>
        </TopNavBase>
    );
};
