import styled from "styled-components";
import {
    Container,
    SectionName,
    SectionHeading,
    SectionSubHeading,
    SectionBase,
    CheckmarkItem,
} from "../../../components";
import {
    Card,
    CardHeading,
    Typography,
    Button,
} from "../../../../design-system";

const Section = styled(SectionBase)`
    background-color: var(--white);
`;

const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-8);
    row-gap: var(--spacing-16);

    @media screen and (max-width: 55em) {
        grid-template-columns: 1fr;
    }
`;

const CardList = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-10);
`;

const CohortDates = styled(Typography)`
    color: var(--primary-600);
    background-color: var(--primary-100);
    width: max-content;
    margin: 0 auto;
    border-radius: 0.4rem;
    padding: var(--spacing-1) var(--spacing-2);
    line-height: 85%;
    margin-bottom: var(--spacing-2);
`;

const PopularCard = styled(Card)`
    background-color: var(--primary-50);
    border: none;
`;

const CardCTA = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    gap: var(--spacing-3);
    justify-content: center;

    a {
        width: 50%;

        @media screen and (max-width: 40em) {
            width: max-content;
        }
        @media screen and (max-width: 30em) {
            width: 100%;
        }
    }
`;

export const Programs = () => {
    return (
        <Section id="programs">
            <Container>
                <SectionName align="center">Programs</SectionName>
                <SectionHeading align="center">
                    Online and in New York
                </SectionHeading>
                <SectionSubHeading align="center">
                    Around your location, schedule and finance.
                </SectionSubHeading>
                <Cards>
                    <Card scaleAnimation>
                        <CardHeading>Frontend Engineering</CardHeading>
                        <CohortDates
                            variant="textL"
                            align="center"
                            weight="medium"
                        >
                            January 21, 2024 - June 9, 2024
                        </CohortDates>
                        <Typography
                            variant="textL"
                            align="center"
                            weight="normal"
                        >
                            $8,000 or $2000 X 4
                        </Typography>
                        <CardList>
                            <CheckmarkItem text="20-week intensive training" />
                            <CheckmarkItem text="3 days a week / 3 hours a day live/in-person workshops" />
                            <CheckmarkItem text="Daily checkins" />
                            <CheckmarkItem text="More than 100 assignments" />
                            <CheckmarkItem text="200-hour video lectures" />
                            <CheckmarkItem text="300-hour text lectures" />
                            <CheckmarkItem text="Group and Personal Capstone projects" />
                            <CheckmarkItem text="Homework and code reviews" />
                            <CheckmarkItem text="Online community | Slack/Discord" />
                            <CheckmarkItem text="Interview Preparation" />
                            <CheckmarkItem text="Career Support" />
                            <CheckmarkItem text="Salary Negotiation Coaching" />
                            <CheckmarkItem text="Potential starting salary: $ 90,000" />
                        </CardList>

                        <CardCTA>
                            <Button
                                color="primary"
                                variant="contained"
                                size="medium"
                                fullWidth={true}
                                renderAsLink
                                navigateTo="apply?programType=frontend"
                            >
                                Apply Now
                            </Button>
                        </CardCTA>
                    </Card>
                    <PopularCard scaleAnimation>
                        <CardHeading>Fullstack Engineering</CardHeading>
                        <CohortDates
                            variant="textL"
                            align="center"
                            weight="medium"
                        >
                            January 21, 2024 - July 7, 2024
                        </CohortDates>
                        <Typography
                            variant="textL"
                            align="center"
                            weight="normal"
                        >
                            $10,000 or $2,500 X 4
                        </Typography>
                        <CardList>
                            <CheckmarkItem text="24-week intensive training" />
                            <CheckmarkItem text="3 days a week / 3 hours a day live.in-person workshops" />
                            <CheckmarkItem text="Daily checkins" />
                            <CheckmarkItem text="More than 120 assignments" />
                            <CheckmarkItem text="250-hour video lectures" />
                            <CheckmarkItem text="350-hour text lectures" />
                            <CheckmarkItem text="Group and Personal Capstone projects" />
                            <CheckmarkItem text="Homework and code reviews" />
                            <CheckmarkItem text="Online community | Slack/Discord" />
                            <CheckmarkItem text="Interview Preparation" />
                            <CheckmarkItem text="Career Support" />
                            <CheckmarkItem text="Salary Negotiation Coaching" />
                            <CheckmarkItem text="Potential starting salary: $ 100,000" />
                        </CardList>
                        <CardCTA>
                            <Button
                                variant="contained"
                                size="medium"
                                fullWidth={true}
                                renderAsLink
                                navigateTo="apply?programType=fullstack"
                            >
                                Apply Now
                            </Button>
                        </CardCTA>
                    </PopularCard>
                </Cards>
            </Container>
        </Section>
    );
};
