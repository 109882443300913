import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { EmailValidator } from "./utils";
const emailValidator = new EmailValidator();

type UseInputReturnType = [
    string,
    Dispatch<SetStateAction<string>>,
    boolean,
    string,
    () => void,
    boolean
];

export const useName = (initialValue: string): UseInputReturnType => {
    const [value, setValue] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [isValidated, setIsValidated] = useState(false);

    const validateName = () => {
        let isValid = true;
        if (value.split(" ").length > 2 || !value.trim()) isValid = false;
        if (value.length > 20) isValid = false;
        for (let char of value.toLowerCase()) {
            if (
                (char.charCodeAt(0) < 97 || char.charCodeAt(0) > 122) &&
                char !== " "
            ) {
                isValid = false;
            }
        }
        setIsError(!isValid);
        setErrorMessage(isValid ? "" : "Enter a valid name");
        setIsValidated(isValid);
    };

    return [value, setValue, isError, errorMessage, validateName, isValidated];
};

export const usePhoneNumber = (initialValue: string): UseInputReturnType => {
    const [value, setValue] = useState(initialValue);
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [isValidated, setIsValidated] = useState(false);

    const validateNumber = () => {
        if (value.length < 10) {
            setIsError(true);
            setErrorMessage("Enter a valid number");
            setIsValidated(false);
        } else {
            setIsError(false);
            setErrorMessage("");
            setIsValidated(true);
        }
    };

    return [
        value,
        setValue,
        isError,
        errorMessage,
        validateNumber,
        isValidated
    ];
};

export const useTextArea = (initialValue: string): UseInputReturnType => {
    const [value, setValue] = useState(initialValue);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isValidated, setIsValidated] = useState(false);

    const validateText = () => {
        if (!value.trim() || value.trim().length < 20) {
            setIsError(true);
            setErrorMessage("Message must have more than 20 characters");
            setIsValidated(false);
        } else {
            setIsError(false);
            setErrorMessage("");
            setIsValidated(true);
        }
    };

    return [value, setValue, isError, errorMessage, validateText, isValidated];
};

export const useEmailInput = (initialValue: string): UseInputReturnType => {
    const [value, setValue] = useState(initialValue);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isValidated, setIsValidated] = useState(false);

    const validateEmail = () => {
        const isEmailValid = emailValidator.validate(value);

        if (isEmailValid) {
            setIsError(false);
            setErrorMessage("");
            setIsValidated(true);
        } else {
            setIsError(true);
            setErrorMessage("Enter a valid email");
            setIsValidated(false);
        }
    };
    return [value, setValue, isError, errorMessage, validateEmail, isValidated];
};
