import { FC } from "react";
import { Button } from "../../../../design-system";
import { TopNav, TopNavLink, TopNavHashLink } from "../../../components";

const NavLinks: FC<{ closeMenu?: () => void }> = ({ closeMenu }) => {
    return (
        <>
            <li>
                <TopNavHashLink to="./#instructor" onClick={closeMenu}>
                    About
                </TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="./#curriculum" onClick={closeMenu}>
                    Curriculum
                </TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="./#programs" onClick={closeMenu}>
                    Programs
                </TopNavHashLink>
            </li>
            <li>
                <TopNavHashLink to="./#admissions" onClick={closeMenu}>
                    Admissions
                </TopNavHashLink>
            </li>
            <li>
                <TopNavLink to="/contact">Contact</TopNavLink>
            </li>
            <li>
                <Button variant="contained" renderAsLink navigateTo="apply">
                    Apply Now
                </Button>
            </li>
        </>
    );
};

export const MainTopNav = () => {
    return (
        <TopNav mobileNavLinks={<NavLinks />}>
            <NavLinks />
        </TopNav>
    );
};
