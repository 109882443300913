import { ScrollRestoration, Outlet } from "react-router-dom";
import { Footer } from "./landing/pages/main/sections";

const App = () => {
    return (
        <>
            <Outlet />
            <Footer />
            <ScrollRestoration />
        </>
    );
};

export default App;
