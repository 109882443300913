import styled from "styled-components";
import { InputBase, InputLabel, InputHint, TextArea } from "./InputBase";
import { cleanupNumberInput, formatPhoneNumber } from "./utils";

interface InputProps {
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    placeholder: string;
    disabled?: boolean;
    error?: boolean;
    hintMessage?: string;
    type?: "tel" | "email" | "password" | "text" | "textarea";
    label?: string;
    id?: string;
    className?: string;
    autoFocus?: boolean;
    tabIndex?: number;
}

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
`;

export const Input: React.FC<InputProps> = ({
    value,
    onChange,
    onBlur,
    placeholder,
    disabled,
    error,
    hintMessage,
    type = "text",
    label,
    id,
    className,
    autoFocus,
    tabIndex,
}) => {
    const handleOnChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (type === "tel") {
            onChange(cleanupNumberInput(e.target.value));
        } else {
            onChange(e.target.value);
        }
    };
    return (
        <InputWrapper className={className}>
            <InputLabel error={error} htmlFor={id}>
                {label}
            </InputLabel>
            {type === "textarea" ? (
                <TextArea
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    error={error}
                    id={id}
                    rows={5}
                    tabIndex={tabIndex}
                />
            ) : (
                <InputBase
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    value={type === "tel" ? formatPhoneNumber(value) : value}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    error={error}
                    id={id}
                    autoFocus={autoFocus}
                    tabIndex={tabIndex}
                />
            )}

            {error && <InputHint error={error}>{hintMessage}</InputHint>}
            {!error && hintMessage && <InputHint>{hintMessage}</InputHint>}
        </InputWrapper>
    );
};
