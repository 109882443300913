import styled, { css } from "styled-components";
import { NavLink, To, useLocation } from "react-router-dom";

const LinkBase = css`
    font-size: var(--font-size-3);
    color: var(--gray-600);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-3);
    transition: all 0.5s;

    &:hover {
        color: var(--primary-600);
    }

    @media screen and (max-width: 60em) {
        font-size: var(--font-size-4);
    }
`;

const TopNavHashLinkBase = styled(NavLink)`
    ${LinkBase}
    &.activeHashLink.active {
        color: var(--primary-600);
    }

    @media screen and (max-width: 60em) {
        font-size: var(--font-size-4);
    }
`;
export const TopNavHashLink: React.FC<{
    to: To;
    children: React.ReactNode;
    onClick?: () => void;
}> = ({ to, children, onClick }) => {
    const location = useLocation();
    const hashIdx = (to as string).indexOf("#");
    const hashPath = (to as string).slice(hashIdx);
    return (
        <TopNavHashLinkBase
            to={to}
            className={hashPath === location.hash ? "activeHashLink" : ""}
            onClick={onClick}
        >
            {children}
        </TopNavHashLinkBase>
    );
};

export const TopNavLink = styled(NavLink)`
    ${LinkBase}

    &.active {
        color: var(--primary-600);
    }
`;
