import styled, { css } from "styled-components";
import { OptionValue, Option } from "./Select";

interface SelectOptionBaseProps {
    $selected: boolean;
}
const SelectOptionBase = styled.li<SelectOptionBaseProps>`
    height: 4.4rem;
    width: 100%;
    padding: 0 0.8rem;
    cursor: pointer;
    border-radius: 0.6rem;

    &:not(:last-child) {
        margin-bottom: 2px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-3);

    &:hover {
        background-color: var(--gray-50);
    }

    ${(props) =>
        props.$selected &&
        css`
            background-color: var(--gray-50);
        `}
`;

interface ReactOptionProps {
    option: Option;
    selectedValue: OptionValue;
    onSelect: (value: Option) => void;
    children: React.ReactNode;
}

export const SelectOptionText = styled.span`
    font-size: 16px;
    color: var(--gray-900);
`;

export const SelectOption: React.FC<ReactOptionProps> = ({
    option,
    selectedValue,
    onSelect,
    children
}) => {
    const isSelected = option.value === selectedValue;
    return (
        <SelectOptionBase
            $selected={isSelected}
            onClick={() => onSelect(option)}
        >
            {children}
            {isSelected && (
                <svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.6668 1L5.50016 10.1667L1.3335 6"
                        stroke="#7F56D9"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </SelectOptionBase>
    );
};
